import HttpBusinessUnitsService from '../../config/HttpBusinessUnits';

export class BusinessUnitsService {
  api: HttpBusinessUnitsService;

  constructor() {
    this.api = new HttpBusinessUnitsService();
  }

  getPudo = (id: string) => {
    return this.api.get(`v1/business-units/${id}`);
  };

  get = (postalCode: string, maxDistanceInMeters = 10000, isFrenet = false) => {
    const url = `v1/business-units/nearby/${postalCode}?max_distance_in_meters=${maxDistanceInMeters}${
      isFrenet ? '&is_frenet=true' : ''
    }`;

    return this.api.get(url);
  };

  searchPudos = (filter: any, currentPage: number, pageSize: number) => {
    return this.api.post(
      `v1/business-units/search_nearby?page_number=${currentPage}&page_size=${pageSize}`,
      filter,
    );
  };

  getDistanceToCep = (id: string, cep: string, isFrenet = false) => {
    return this.api.get(`v1/business-units/${id}/distance/${cep}`);
  };

  getDistanceToReceiver = (cep: string) => {
    return this.api.get(`v1/business-units/exist_nearby/${cep}`);
  };
}

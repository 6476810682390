import { createSlice } from '@reduxjs/toolkit';
import { CONST_LOCALSTORAGE } from '../constants/localStorage';

export type CartParameters = {
  amount: number;
  cartOrderMessageAdd: boolean;
  quantity: number;
};

export type TypeCart = {
  cart: CartParameters;
};

function getCart() {
  const cart = localStorage.getItem(CONST_LOCALSTORAGE.CART_INFORMATION);
  return JSON.parse(cart || '{}');
}

function setUpdateCart(action: any) {
  localStorage.setItem(
    CONST_LOCALSTORAGE.CART_INFORMATION,
    JSON.stringify(action.payload),
  );

  return action.payload;
}

function setOrderMessageDisable(state: any) {
  localStorage.setItem(
    CONST_LOCALSTORAGE.CART_INFORMATION,
    JSON.stringify({ ...state, cartOrderMessageAdd: false }),
  );

  return { cartOrderMessageAdd: false };
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    quantity: 0,
    amount: 0,
    cartOrderMessageAdd: false,
  },
  reducers: {
    updateCart: (state, action) => {
      return { ...state, ...setUpdateCart(action) };
    },
    orderMessageDisableCart: state => {
      return { ...state, ...setOrderMessageDisable(state) };
    },
  },
});

export const { updateCart, orderMessageDisableCart } = cartSlice.actions;

export const selectCart = (state: TypeCart) => {
  return {
    ...state.cart,
    ...getCart(),
  };
};

export default cartSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import sellerInfoSlice from './sellerInfo';
import cartSlice from './cart';
import walletSlice from './wallet';
import orderDetailsSlice from './orderDetails';

export default configureStore({
  reducer: {
    sellerInfo: sellerInfoSlice,
    cart: cartSlice,
    wallet: walletSlice,
    orderDetails: orderDetailsSlice,
  },
});

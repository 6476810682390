import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import { IColorsModal } from '../@types';

export const HeaderModal = styled(Modal.Header)`
  display: flex;
  padding: 18px 18px 0px 30px;
`;

export const TitleModal = styled.h4<IColorsModal>`
  font-size: 18px;
  color: ${props =>
    props.colors === 'danger' ? '#E87960' : '#03ad75'} !important;

  text-align: center;
  width: 100%;

  @media (min-width: 576px) {
    font-size: 24px;
    text-align: left;
  }
`;

export const CloseButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-radius: 15px;
  border: none;
  background-color: #8e9ca8;

  color: #fff;
  font-size: 20px;

  @media (min-width: 576px) {
    display: flex;

    width: 50px;
    height: 50px;

    font-size: 30px;
    border-radius: 25px;

    position: absolute;
    top: -24px;
    right: -12px;
  }
`;

export const BodyContainer = styled(Modal.Body)`
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`;

export const TextModal = styled.p`
  font-size: 14px;
  color: #004d59;
  line-height: 1.5rem;
  padding: 0 10px;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;

  width: 142px;

  @media (min-width: 576px) {
    width: 192px;
  }
`;

export const ButtonModal = styled(Button)<IColorsModal>`
  background-color: #03ad75 !important;
  border-radius: 13px;
  color: #fff;
  width: 100%;
  border: none;
`;

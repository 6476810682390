/* eslint-disable eqeqeq */

import { ClientData } from '../service/shared/clients.types';

/* eslint-disable no-plusplus */
const formatedMoney = new Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
});

const formatMoney = (value: number) => {
  return value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
};

const formatCEP = (cep: string): string => {
  const regex = /^([\d]{2}).?([\d]{3})-?([\d]{3})/;

  if (regex.test(cep)) {
    return cep.replace(regex, '$1.$2-$3');
  }

  return '';
};

const formatCpf = (value: string): string => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

const formatCnpj = (value: string): string => {
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

const formatCpfOrCnpj = (textField: string | undefined): string => {
  if (textField) {
    if (textField.length <= 11) {
      textField = formatCpf(textField);
    } else {
      textField = formatCnpj(textField);
    }

    return textField;
  }

  return 'Não informado';
};

const formatPhone = (v: string): string => {
  if (v && v.length === 11 && v[2] === '9') {
    return v.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
  }
  return v.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
};

const extractValueObjects = (array: any, key: string) => {
  return array.map((element: any) => element[key]);
};

const createUUID = () => {
  function random() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${random()}${random()}-${random()}-${random()}-${random()}-${random()}${random()}${random()}`;
};

const removeElement = (array: any, key: string, someValue: any) => {
  return array.filter((item: any) => item[key] !== someValue);
};

function validarCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj === '') return false;
  if (cnpj === '' || cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2; // 12
  let numeros: any = cnpj.substring(0, tamanho); // 10129300 0001
  const digitos: any = cnpj.substring(tamanho); // 29
  let soma = 0;
  let pos = tamanho - 7; // 5

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

function validarCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '' || cpf.length !== 11) return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += Number(cpf.charAt(i)) * (10 - i); // eslint-disable-line
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += Number(cpf.charAt(i)) * (11 - i); // eslint-disable-line
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(10))) return false;
  return true;
}

const formatDateUtc = (date: string, justDate?: boolean) => {
  const newData = new Date(date);

  if (justDate)
    return date !== '' ? newData.toLocaleDateString() : 'Data não informada';
  return date !== '' ? newData.toLocaleString() : 'Data não informada';
};

const formatDatePagSeguro = (oldDate: string) => {
  const offset = new Date().getTimezoneOffset();
  const formatted = -(offset / 60);
  const date = new Date(oldDate);
  date.setHours(date.getHours() + formatted);
  const newData = new Date(date).toLocaleString().slice(0, -3);

  return oldDate !== '' ? newData : 'Data não informada';
};

const getVariant = (name: string) => {
  if (name === 'secondary') return 'secondary';
  if (name === 'danger') return 'danger';
  if (name === 'orange') return 'orange';
  if (name === 'gray') return 'gray';

  return 'primary';
};

const isActiveProfile = (profile: ClientData | undefined): boolean => {
  if (
    profile &&
    (profile.phone_number === '' || profile.phone_number === null) &&
    (profile.address.postal_code === '' || profile.address.postal_code === null)
  )
    return false;
  return true;
};

const sortByKey = (array: any, key: string) => {
  return array.sort((a: any, b: any) => {
    const x = a[key];
    const y = b[key];

    return x < y ? -1 : x > y ? 1 : 0;
  });
};

const dateFormatToTimezone = (date: string, showSeconds = false) => {
  if (date || date !== '') {
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + formatted);

    if (showSeconds) return new Date(formattedDate).toLocaleString();

    return new Date(formattedDate).toLocaleString().slice(0, -3);
  }

  return 'Data não informada';
};

export {
  formatedMoney,
  formatMoney,
  formatCEP,
  formatCpf,
  formatCnpj,
  formatCpfOrCnpj,
  formatPhone,
  formatDateUtc,
  extractValueObjects,
  createUUID,
  removeElement,
  validarCNPJ,
  validarCPF,
  getVariant,
  isActiveProfile,
  sortByKey,
  formatDatePagSeguro,
  dateFormatToTimezone,
};

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClientsService } from '../../service';
import useAuth0Credentials from '../../hooks/useAuth0Credentials';
import {
  selectSellerInfo,
  updateLoading,
  updateSellerInfo,
} from '../../redux/sellerInfo';
import * as S from './styles';

export function CheckSellerValidated() {
  const clientService = useMemo(() => new ClientsService(), []);

  const { userId } = useAuth0Credentials();

  const { email_validated, phone_validated, loading, alredy_checked } =
    useSelector(selectSellerInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
  };

  useEffect(() => {
    if (!userId || alredy_checked) return;

    dispatch(updateLoading(true));

    clientService
      .getClient(userId)
      .then(({ data }) => {
        dispatch(
          updateSellerInfo({
            email_validated: data.email_validated,
            phone_validated: data.phone_validated,
            alredy_checked: true,
            loading: false,
          }),
        );
      })
      .finally(() => dispatch(updateLoading(false)));
  }, [userId, clientService, dispatch, alredy_checked]);

  return (!email_validated || !phone_validated) && !loading ? (
    <S.ToolTipValidated onClick={goToProfile}>
      <S.TextModal>
        Sua conta não está validada! Verifique seu perfil.
      </S.TextModal>
    </S.ToolTipValidated>
  ) : null;
}
